export const navLinksData = [
  {
    id: 1001,
    title: "Home",
    link: "home",
  },
  {
    id: 1004,
    title: "Resume",
    link: "resume",
  },
  {
    id: 1003,
    title: "Projects",
    link: "projects",
  },
  {
    id: 1006,
    title: "Contacts",
    link: "contacts",
  },
];
